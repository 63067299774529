.form-invite-modal input::selection,
textarea::selection {
  background-color: #cce2ff;
}

.form-invite-modal .ui.form input[type='email']:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.form-invite-modal .ui[class*='right labeled'].input > input:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.form-invite-modal .ui.input .btn-copy {
  margin: 0;
}

.form-invite-modal .ui.input > input:focus {
  border-left: none;
}

.form-invite-modal .ui.input > input[type='button'] {
  cursor: pointer;
  background: #f4f4f4;
  border-left: none;
}

.form-invite-modal .ui.form.dynamic-form.ui.form textarea {
  height: 12em;
}
.form-invite-modal .ui.form.dynamic-form.ui.form textarea:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.form-invite-modal .invite-form .fields .field + .field {
  flex: 2 2 auto !important;
}

.form-invite-modal .invite-form-list .field + .field {
  width: 50%;
}

.organization .member-search.ui.form input[type='text'] {
  padding-right: 2em;
}

.organization .org-tabs-container {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.organization .org-tab {
  display: inline-block;
  padding: 1vh 1vw;
  font-size: 1.25em;
}

.organization .org-tab.active {
  font-weight: bold;
  border-bottom: 2px solid #76d573;
}

.organization .member-search .ui.basic.button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: -2.75em;
}

.organization-group-members .member-table-options .button {
  display: inline-block;
}

.organization .invite-btn {
  text-align: right;
}

.organization .member-search {
  text-align: right;
}

.invite-members-modal {
  text-align: center;
}
.invite-members-modal p {
  color: #fff;
  font-size: 1.5em;
}

.invite-members-modal .invite-url {
  border: 1px solid #fff;
  padding: 1em 3.5em 1em 1em;
  border-radius: 5px;
  font-size: 1.25em;
  color: #2478c2;
  display: inline-block;
  width: 100%;
}

.invite-members-modal .invite-url input {
  overflow-x: scroll;
  background: transparent;
  border: none;
  color: #2478c2;
  padding: 0;
}

.invite-members-modal .invite-url input:focus {
  border: none;
}

.invite-members-modal .invite-url + .icon {
  color: #fff;
  margin-left: -1.5em;
  margin-right: 0;
  font-size: 2em;
  vertical-align: text-bottom;
}

.invite-members-modal .invite-url + .icon:hover {
  color: #2ecc40;
  cursor: pointer;
}

.invite-members-modal .invite-container {
  margin: 3em 0;
}

.organization .ui.back-button {
  margin-top: 2em;
}

.org-member-avatar {
  max-width: 60px;
  border-radius: 50%;
}

.organization .profile-avatar {
  width: 100%;
  text-align: center;
}

.organization .profile-avatar img {
  width: 100%;
  max-width: 150px !important;
  border-radius: 50%;
  box-shadow: 2px 4px 8px #00000094;
}

.organization .profile-table td,
.organization .profile-table table {
  border: none !important;
}

.organization .ui.grid > .row.profile-data {
  padding: 0.25em;
  font-size: 1.125em;
}

.organization .row.profile-table-actions {
  margin-top: 0;
}

.organization .organization-member-profile .column > .grid {
  margin: 0;
}

.organization .organization-member-profile .row.profile-earned-certs {
  margin-top: 0;
  padding-top: 0;
}

.organization .organization-member-profile .profile-table .responsive-table-container {
  margin: 0 !important;
}

.organization .row.profile-earned-certs {
  margin: 1em 0 2em 0;
}

.organization-member-profile .profile-role-row p {
  display: inline-block;
}

.organization-member-profile .profile-role-row .ui.fluid.dropdown {
  display: inline-block;
  width: auto;
  min-width: 13em;
  margin-left: 1em;
}

.organization .transition .thumbs.up.outline.icon {
  float: left;
}

.organization .organization-group-members.ui.segment {
  padding-left: 0;
  padding-right: 0;
}

.organization-group-members .header.group-name {
  display: inline-block;
}

.organization-group-members .header.group-name + * {
  margin-left: 1em;
  vertical-align: super;
  font-size: 1.25em;
  color: #4183c4;
  cursor: pointer;
}

.organization .ui.grid.groups-crumb-container {
  margin-bottom: 0.5em;
}

.state-tabs {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 1em;
}

.state-tabs .tab {
  display: inline-block;
  padding: 1vh 0.75em;
}

.state-tabs .tab:hover {
  cursor: pointer;
}

.state-tabs .tab.active {
  font-weight: bold;
  border-bottom: 2px solid #76d573;
}

.state-tabs .tab.inactive:hover,
.organization .org-tab.inactive:hover {
  border-bottom: 2px solid #dedede;
}

.organization .row.row-no-btm-padding {
  padding-bottom: 0 !important;
}

.enterprise-group-switcher .ui.selection.dropdown.switch-group-dropdown {
  min-width: 50%;
}

.curricula-reports-icon {
  font-size: 1.75em;
}

.curricula .router-tabs {
  margin-bottom: 2em;
}

.curricula .router-tabs .tab {
  text-align: center;
}

.org-members .auto-grant-text {
  margin-left: 1em;
}

.org-members .auto-grant-text,
.org-members .auto-grant-text * {
  vertical-align: top;
}

.org-members .auto-grant-text .green {
  color: #0db350;
  font-weight: 700;
}

.org-members .auto-grant-text .red {
  color: #ed1e44;
  font-weight: 700;
}

.org-members .search {
  text-align: right;
}

.org-members .search .ui.input,
.organization .member-table-options .change-role-dropdown,
.team-actions .actions-container .ui.search .input {
  width: calc(100% - 2px);
  max-width: 450px;
}

.org-members .ui.grid > .row > .column.view-team-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.org-members .ui.grid > .row > .column.view-team-img img {
  max-width: 200px;
}

.org-members .boot-chat {
  color: #4183c4;
}

.org-members .boot-chat:hover {
  cursor: pointer;
}

.org-members .no-results {
  margin: 1em 0 0 1em;
  font-size: 1.25em;
}

.org-members .header-section.ui.grid {
  margin-bottom: 0.25em;
}

.org-members .Collapsible .is-open + .Collapsible__contentOuter,
.organization-group-members .Collapsible .is-open + .Collapsible__contentOuter {
  overflow: visible !important;
}

.team-actions .segment.collapse-container.transition {
  overflow: hidden !important;
  height: 110px;
}

.invited-members-list p {
  margin-bottom: 0;
}

.invited-members-list .ui.list > .item {
  padding: 0.5em 0 !important;
}

.invited-members-list .ui.list > .item:first-child {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.invited-members-list .ui.list > .item:last-child {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.invited-members-list .invite-date {
  color: #949090;
  font-size: 0.875em;
}

.invited-members-list .list-actions {
  margin-top: 0.75em !important;
}

.invited-members-list .list-actions .icon {
  color: #949090;
  cursor: pointer;
}

.invited-members-list .list-content .label {
  vertical-align: middle;
}

.team-actions .search .ui.basic.button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: -2.75em;
}

.team-actions.ui.basic.segment {
  padding: 1em 0 0 0;
}

.team-actions.ui.basic.segment > .button {
  margin-top: 0.5em;
}

.team-actions .button {
  height: 100%;
}

.team-actions .ui.header {
  font-weight: 600;
}

.enterprise-homepage .team-logo {
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enterprise-homepage .team-logo img {
  width: 100%;
}

.enterprise-homepage .team-logo .icon {
  color: #fff;
  font-size: 3em;
  line-height: 1em;
  vertical-align: bottom;
  opacity: 0;
  margin: auto;
  transition: all 0.5s ease;
}

.enterprise-homepage .team-logo.empty .icon {
  opacity: 1;
}

.enterprise-homepage .team-logo {
  transition: all 0.5s ease;
}

.enterprise-homepage .team-logo:not(.disabled):not(.restricted):hover {
  opacity: 0.7;
  cursor: pointer;
  /* background-color: #0000004a; */
}

.enterprise-homepage .team-logo:not(.disabled):not(.restricted):hover .icon {
  opacity: 1;
}

/* ***** Dashboards ***** */

.organization .dashboard .med-weight {
  font-weight: 600;
}

.organization .dashboard .header-container p {
  margin-bottom: 0;
}

.organization .dashboard .header-container .header-image img {
  border-radius: 5px;
}

.organization .dashboard .group-cards-container .card .header {
  font-size: 1em !important;
  font-weight: 600 !important;
}

.organization .dashboard .group-cards-container .card .meta {
  font-size: 0.875em !important;
}

.organization .dashboard .group-cards-container .card {
  text-align: center;
}

.organization .dashboard .group-cards-container .card.disabled {
  cursor: default !important;
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  transform: translateY(0px) !important;
}

.organization .dashboard .group-cards-container .card .header {
  margin-bottom: 2em;
}

.organization .dashboard .group-cards-container .card .label {
  position: absolute;
  bottom: 1em;
  left: 50%;
  width: 80%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.organization .dashboard .stat-card-loading-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.organization .dashboard .doughnut .doughnut-container .meta {
  margin-top: -2em;
}

.organization .dashboard .groups-collapsible {
  padding-bottom: 1.75em;
}

.organization .dashboard .groups-collapsible .Collapsible__trigger > div {
  cursor: pointer;
  font-weight: 600;
  color: #4183c4;
  position: absolute;
  bottom: 0.75em;
  left: 45%;
}

.organization .dashboard .groups-collapsible .group-cards {
  padding-bottom: 0.25em;
}

.organization .dashboard .groups-collapsible .Collapsible__trigger {
  padding-top: 0;
  transition: all 0.3s ease;
}

.organization .dashboard .groups-collapsible .Collapsible__trigger.is-open {
  padding-top: 1.5em;
}

.organization .dashboard .groups-collapsible .Collapsible__trigger .icon {
  transition: all 0.3s ease;
  vertical-align: text-top;
}

.organization .dashboard .groups-collapsible .Collapsible__trigger.is-open .icon {
  transform: rotate(180deg);
  vertical-align: bottom;
}

/* ***** Member Dashboard ***** */

.organization-member-dashboard .expanded-assignments-heading.ui.table {
  margin-bottom: 0;
}

.organization-member-dashboard .expanded-assignments-heading.ui.table th {
  background-color: #fff;
}

.organization-member-dashboard .expanded-assignments-table {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.expanded-assignments-table .ui.compact.table th {
  font-weight: 600;
  font-size: 14px;
  background: #fff;
}

.organization-member-dashboard .profile-data h1 {
  margin-bottom: 0.25em;
}

.organization-member-dashboard .profile-data p {
  margin: 0;
}

.organization-member-dashboard .profile-data p:last-of-type {
  margin-bottom: 0.5em;
}

.organization-member-dashboard .header-container .user-detail,
.organization .dashboard .header-container .heading-detail {
  font-weight: 600;
  margin-right: 0.75em;
  color: #637381;
}

.organization .dashboard .sticky .ui.segment.filter-container {
  margin-bottom: 1em;
}

.filter-container .ui.fluid.dropdown {
  max-width: 300px;
  display: inline-block;
}

.filter-container .group-dropdown .ui.fluid.dropdown {
  max-width: none;
}

.organization .dashboard .filterable-content .stuck-container .filter-container {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border-bottom: 0.5px solid #cccccc !important;
}

.organization-member-dashboard .change-role-dropdown {
  margin: 0;
}

.organization-member-dashboard .header-container .license-toggle {
  margin-left: 0.25em;
  font-size: 0.875em;
}

.organization-member-dashboard .header-container .license-toggle,
.organization-member-dashboard .header-container .license-toggle span {
  vertical-align: top;
}

.organization-member-dashboard .header-container .profile-title {
  font-size: 1.125em;
  color: #637381;
  font-weight: 600;
}

.organization-member-dashboard .header-container {
  margin-bottom: 1em;
}

.organization .dashboard .whitespace-nowrap {
  white-space: nowrap;
}

.organization .dashboard .header-container .sub-data p {
  margin-bottom: 0.25em;
}

.organization-member-dashboard .header-container .admin-options > * {
  margin-bottom: 0.5em;
}

.organization-member-dashboard .header-container .admin-options {
  text-align: right;
}

.organization .dashboard .header-container .header-content .row + .row {
  padding-top: 0;
}

.organization-member-dashboard .header-container .admin-options .license-toggle .green {
  color: #21ba45;
  font-weight: 700;
}

.organization-member-dashboard .get-certificate:hover {
  cursor: pointer;
}

.organization-member-dashboard .get-certificate {
  color: #4183c4;
}

.organization .dashboard .expanded-assignments-table .no-assignment-activity {
  padding: 1em;
}

.organization .dashboard .learning-hours canvas {
  height: 350px !important;
}

/* ***** End Member Dashboard *****  */

/* ***** Admin Member Dashboard ***** */
.organization .dashboard .admin-user-subscription tbody .expand-column-chevron:before {
  content: '\F054';
}

.organization .dashboard .admin-user-subscription tbody .expand-column-chevron:after {
  content: '\F078';
}

/* ***** End Admin Member Dashboard ***** */

/* ***** Assignment Dashboard ***** */

.organization-assignment-dashboard .header-container .assignment-name-container h1 {
  display: inline-block;
  margin-bottom: 0.5em;
}

.organization-assignment-dashboard .header-info .curricula-link {
  font-size: 1.125em;
  display: inline-block;
  margin-bottom: 0.5em;
}

.organization-assignment-dashboard .header-info .label {
  margin-left: 0;
  margin-top: 0.5em;
}

.organization-assignment-dashboard .edit-group-link {
  display: block;
  margin-bottom: 0.75em;
}

.organization-assignment-dashboard .edit-group-link .button,
.organization-assignment-dashboard .edit-group-link + .button {
  min-width: 8.5em;
}

/* ***** End Assignment Dashboard ***** */

.team-hierarchies .groups-container.grid {
  margin-bottom: 1em !important;
}

.team-hierarchies .search-container .input {
  width: 100%;
  max-width: 400px;
}

.team-hierarchies .groups-container .Collapsible {
  padding-right: 0;
}

.team-hierarchies .groups-row {
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-bottom: 1rem;
  width: 100% !important;
  position: relative;
  padding: 0.5em 0 !important;
}

.team-hierarchies .groups-row .column {
  vertical-align: middle !important;
}

.team-hierarchies .groups-row .group-color {
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.team-hierarchies .groups-row.add-group-row {
  color: rgba(40, 40, 40, 0.3);
  min-height: 56px;
}

.team-hierarchies .empty-data-message * {
  color: #637381;
}

.team-hierarchies .groups-row.add-group-row,
.team-hierarchies .groups-row.add-group-row .column {
  padding: 0 !important;
  border: none;
}

.team-hierarchies .groups-row.add-group-row .group-color {
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
}

.team-hierarchies .groups-row.add-group-row .column,
.team-hierarchies .groups-row.add-group-row .column .input {
  height: 100%;
  width: 100%;
}

.team-hierarchies .groups-row .group-name-column.clickable span {
  cursor: pointer;
}

.team-hierarchies .groups-row .group-name-column,
.team-hierarchies .groups-row.add-group-row .column input {
  padding-left: 1.5em !important;
}

.team-hierarchies .groups-row .sub-group-column {
  color: #949090;
}

.team-hierarchies .groups-row .sub-group-column.has-children {
  color: #141414;
  cursor: pointer;
}

.team-hierarchies .groups-row .admins {
  display: flex;
}

.team-hierarchies .groups-row .column.center .admins {
  justify-content: center;
}

.team-hierarchies .groups-header .group-col.sortable span {
  cursor: pointer;
  white-space: nowrap;
}

.team-hierarchies .groups-header .group-col.active {
  font-weight: 700;
}

.team-hierarchies .groups-container .sub-groups-container {
  width: 100%;
}

.team-hierarchies .groups-container .sub-groups-container.open {
  margin: -1rem 0;
}

.team-hierarchies .groups-container .sub-groups-container > .Collapsible__contentOuter {
  transition: all 200ms ease !important;
  opacity: 0;
}

.team-hierarchies .groups-container .sub-groups-container.open > .Collapsible__contentOuter {
  overflow: inherit !important;
  opacity: 1;
}

.team-hierarchies .add-group-row input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #df057b !important;
  opacity: 1; /* Firefox */
}

.team-hierarchies .add-group-row input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #df057b !important;
}

.team-hierarchies .add-group-row input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #df057b !important;
}

.organization-create-group .create-group-form {
  max-width: 500px;
}

.request-license .invite-manager p {
  font-size: 0.875em;
  margin-bottom: 0.5em;
  margin-top: 1.25em;
}

.request-license .invite-manager .input {
  min-width: 400px;
  margin-right: 1em;
}

.request-license .invite-manager .input input {
  padding: 0.3875em 0.875em;
}

@media screen and (max-width: 1275px) {
  .team-actions.segment.collapse-container,
  .team-actions .segment.collapse-container.transition {
    height: 120px;
  }
}

.curriculum-details .content-item + .content-item {
  padding-top: 0 !important;
}

.curriculum-details .content-item .index {
  width: 2.5em;
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875em;
}

.curriculum-details .content-item .duration {
  font-size: 0.875em;
}

.request-license .responsive-table-container {
  width: 75%;
}

@media screen and (max-width: 1100px) {
  .organization .dashboard .group-cards-container a.ui.raised.card {
    width: calc(25% - 1.5em);
  }

  .request-license .responsive-table-container {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .organization .dashboard .sticky .ui.segment.filter-container {
    margin-bottom: 2em;
  }

  .organization .dashboard .sticky.fixed {
    position: relative !important;
    top: 0 !important;
    margin-bottom: 1em;
  }

  .organization .dashboard .filterable-content .stuck-container .filter-container {
    margin-left: -0 !important;
    margin-right: -0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom: none !important;
  }

  .organization .dashboard .filterable-content .stuck-container > div:first-child {
    height: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .organization-member-dashboard .header-container .admin-options > * {
    text-align: left;
    display: block;
  }

  .organization .admin-options {
    text-align: left;
    margin-top: 2em;
  }

  .organization .dashboard .header-container .admin-items button {
    margin-top: 1em;
  }

  .org-members .search .ui.input,
  .organization .member-search .ui.input,
  .organization .member-table-options .change-role-dropdown {
    max-width: 100%;
  }

  .team-actions .segment.collapse-container,
  .team-actions .segment.collapse-container.transition {
    height: auto;
  }

  .team-actions .actions-container .column + .column {
    margin-top: 1em;
  }

  .team-actions .actions-container .column {
    margin: 0.5em 0 !important;
  }

  .team-actions .actions-container .column > .button {
    width: 100%;
  }

  .team-actions .actions-container .ui.search .input {
    max-width: 100%;
  }

  .team-hierarchies .hierarchy-container > .groups-container.grid {
    min-width: 700px;
  }
}

@media screen and (max-width: 993px) {
  .organization .dashboard .member-dashboard-assignments > .ui.grid > .row > [class*='right aligned'].column.column {
    display: block;
    margin-top: 1em;
    width: 100% !important;
    text-align: left;
  }

  .organization .tablet-align-left .org-members .tablet-align-left,
  .organization-group-members .tablet-align-left {
    text-align: left !important;
  }

  .team-actions.row > .column {
    margin: 0.25em 0;
  }

  .organization-group-members > .ui.stackable.grid > .row > .wide.column {
    padding: 0.25em 1em !important;
  }

  .organization-assignment-dashboard .header-container .admin-items {
    text-align: left !important;
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 765px) {
  .organization .dashboard .group-cards-container {
    margin-top: 2em;
  }

  .organization .dashboard .filterable-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .organization-assignment-dashboard .header-container.ui.segment {
    margin-bottom: 2em;
  }

  .org-members .view-team-img,
  .organization-assignment-dashboard .header-image {
    display: none !important;
  }

  .team-actions.row {
    padding: 0;
  }

  .form-invite-modal .invite-form-list .field + .field {
    width: 100%;
  }
}

@media screen and (max-width: 675px) {
  .organization .member-search .ui.input {
    width: 99%;
  }

  .organization .dashboard .admin-options {
    margin-top: 0em;
  }

  .organization .dashboard .date-pickers-container {
    display: block;
  }

  .filter-container .ui.fluid.dropdown {
    max-width: 200px;
  }

  .request-license .invite-manager .input {
    min-width: 0;
  }
}

.manage-member-licenses-select__value-container--has-value > input {
  display: none;
}

.manage-member-licenses-select__value-container {
  padding: 0px !important;
  width: 190px;
}

.manage-member-licenses-select__multi-value__label,
.manage-member-licenses-select__multi-value {
  color: rgb(51, 51, 51) !important;
  background-color: #fff !important;
  border-radius: 0;
  font-weight: 400 !important;
  font-size: 14px !important;
  /* text-shadow: 0 0 2px #000; */
  /* width: 100% !important; */
  text-align: left !important;
  padding: 0px 8px 0px 0px !important;
}

.manage-member-licenses-select__multi-value {
  width: 100%;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  margin: 0 0.25rem !important;
}

.manage-member-licenses-select__multi-value__remove {
  color: rgb(200, 200, 200) !important;
  border: 0 !important;
  padding: 0px !important;
  margin: 2px 4px 2px 6px !important;
}

.manage-member-licenses-select__multi-value__remove svg {
  width: 16px !important;
  height: 16px !important;
}

.manage-member-licenses-select__multi-value__remove button {
  padding: 0px 1px !important;
}

.manage-member-licenses-select__multi-value__remove:hover {
  color: #a50000 !important;
  background-color: #fff !important;
}

.auto-text-manage-member-licenses {
  padding: 0px !important;
}

.manage-member-licenses-select__dropdown-indicator {
  cursor: pointer !important;
  /* color: #e2037a !important; */
}

.manage-member-licenses-select__indicators button svg {
  cursor: pointer !important;
  color: #e2037a !important;
}

.manage-member-licenses-select__value-container--has-value {
  flex-direction: column;
}

.manage-member-licenses-select__indicators > button {
  padding: 4px 8px !important;
}

.manage-member-licenses-select__placeholder {
  padding: 0px 21px !important;
}
