.reports .align-right {
  text-align: right;
}

.reports .report-chart {
  margin-top: 1em;
}

.reports .adv-opts {
  padding-bottom: 1em;
}

.reports .adv-opts .adv-opts-hook + div {
  transition: all 0.25s linear;
  max-height: 20em;
  padding-top: 0;
}

.reports .adv-opts-hook.closed + .row {
  max-height: 0;
  padding: 0;
}

.reports .adv-options-link {
  font-size: 1.25em;
}

.reports .adv-options-link > i {
  vertical-align: middle;
}

.reports .adv-options-link.rotate > .right {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  vertical-align: bottom;
}

.reports .adv-opts .column + .filter-col:empty {
  margin: 0 !important;
}

.reports .search-container .input,
.reports .search-container input,
.reports .search-container > div {
  width: 100%;
}

.reports .search-container .ui.search .prompt {
  border-radius: 0;
}

.reports .search-container .search.icon {
  position: absolute;
  right: 20px;
  top: 10px;
  color: rgba(34, 36, 38, 0.45);
}

.reports .search-container .search.icon:hover,
.reports .cyb-app .activity-table thead th:hover,
.reports .adv-options-link:hover {
  cursor: pointer;
}

.organization.reports .table-container {
  overflow-x: scroll;
}

.reports .cal-to {
  margin: 0 2%;
}

.reports .ui.table.activity-table td.col-centered {
  text-align: center;
}

.reports .table-no-results {
  padding: 1em;
}

.activity-table .header-sortable {
  white-space: nowrap;
}
.activity-table .header-sortable:hover {
  cursor: pointer;
}

.scheduled-reports-form #email {
  max-width: 750px;
}

.scheduled-reports-form #frequency {
  width: auto;
}

.scheduled-reports-form .ui.basic.segment.report-details {
  padding: 1.5em 0;
  margin-bottom: 0.5em;
}

.scheduled-reports-form .ui.form .field > .selection.dropdown {
  width: auto;
}

.scheduled-reports-form .report-details .grid > .row {
  padding: 0.125em 0;
}

.schedule-reports .admin-table .manage-column > .icon {
  margin-left: 0.5em;
  vertical-align: text-top;
}

.scheduled-reports-form .report-details .grid > .row .label {
  font-weight: 600;
}

.scheduled-reports-form .header {
  margin-bottom: 1em;
}

.scheduled-reports-form .dynamic-form {
  margin-bottom: 1em;
}

.schedule-reports .admin-table .icon:hover {
  cursor: pointer;
}

.schedule-reports .manage-column {
  white-space: nowrap;
}

.reports .adv-opts .search-dates > .column > div + div {
  margin-top: 2em;
}

.reports .adv-opts .search-dates > .column .date-range-filter .dropdown {
  margin-bottom: 2em;
}

.reports.deprecated .message .icon {
  font-size: 2em !important;
}

@media screen and (max-width: 991px) {
  .reports .adv-opts .row .column {
    display: block;
    text-align: left;
  }

  .reports .adv-opts .row .column + .column {
    margin-top: 1em;
  }

  .reports .adv-opts.ui.grid > .row.search-dates {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .reports .row .column {
    text-align: left;
  }
}

@media screen and (max-width: 575px) {
  .reports .row .column {
    text-align: left;
  }

  .reports .opts-btns .button + .button {
    margin-top: 1em;
  }
}
