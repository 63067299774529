.loading-square {
  width: 36px;
  aspect-ratio: 1;
  --color: rgb(41, 41, 41);
  --g1: conic-gradient(from 90deg at 4px 4px, #00000000 90deg, var(--color) 0);
  --g2: conic-gradient(from -90deg at 12px 12px, #00000000 90deg, var(--color) 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  animation: loadingSquare 1.5s cubic-bezier(0.33, 1, 0.68, 1) infinite;
}
@keyframes loadingSquare {
  0% {
    background-position: 0 0, 0 100%, 100% 100%;
  }
  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }
  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }
  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }
  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}
